import apiConfig from "@/configs/apiConfig";
import { Api } from "@/types/api";
import { refreshToken } from "./auth";

const balelinkApiClient = new Api<{ token?: string }>({
	baseApiParams: { secure: true },
	securityWorker: data =>
		data?.token
			? {
					headers: { Authorization: `${data.token}` },
				}
			: {},
	customFetch: async (input, init) => {
		try {
			const token = await refreshToken("id");
			if (token && token !== cachedJwt) {
				initBaleLinkAPIClient(token);
				if (init?.headers && "Authorization" in init?.headers) {
					init.headers["Authorization"] = `${token}`;
				}
			}

			return await fetch(input, init);
		} catch (err) {
			console.error("Encountered errors while refreshing token", err);
			return Promise.reject(err);
		}
	},
});

let cachedJwt = "";

/**
 * Initializes the httpClient for a given page by supplying necessary
 * credentials such as the authorization token.
 *
 * This method should be triggered on every page navigation.
 */
export function initBaleLinkAPIClient(jwt: string) {
	cachedJwt = jwt;

	const baseUrl =
		apiConfig.APIGateway.baseApiGatewayUrl +
		"/" +
		apiConfig.APIGateway.branch;
	balelinkApiClient.baseUrl = baseUrl;
	balelinkApiClient.setSecurityData({
		token: cachedJwt,
	});
}

/**
 * Sends requests using methods defined in HttpClient from types/api.ts.
 * Swagger generates a HTTP Client with predefined methods for each known method.
 *
 * Since the context of the request is supplied on page navigation,
 * it is not necessary to pass in the information during the request.
 *
 * To call a request, call an existing route with the necessary parameter as follows:
 *
 * eg)
 * const res = await httpClient.bales.getBales(minLat, minLon, maxLat, maxLon, minDate);
 */
export const useBalelinkApi = (): Api<{ token?: string }> => balelinkApiClient;
