import apiConfig from "@/configs/apiConfig";
import { Api } from "@/types/umcApi";
import { refreshToken } from "./auth";

const umcApiClient = new Api<{ token?: string }>({
	baseApiParams: { secure: true },
	securityWorker: data =>
		data?.token
			? {
					headers: { Authorization: `${data.token}` },
				}
			: {},
	customFetch: async (input, init) => {
		try {
			const token = await refreshToken("id");
			if (token && token !== cachedJwt) {
				initUmcAPIClient(token);
				if (init?.headers && "Authorization" in init?.headers) {
					init.headers["Authorization"] = `${token}`;
				}
			}

			return await fetch(input, init);
		} catch (err) {
			console.error("Encountered errors while refreshing token", err);
			return Promise.reject(err);
		}
	},
});

let cachedJwt = "";

const unauthenticatedUmcApiClient = new Api<{}>({
	baseApiParams: { secure: true },
	customFetch: async (input, init) => {
		try {
			initUmcUnauthAPIClient();
			return await fetch(input, init);
		} catch (err) {
			console.error("Error in unauthenticated customFetch", err);
			return Promise.reject(err);
		}
	},
});

/**
 * Initializes the httpClient for a given page by supplying necessary
 * credentials such as the authorization token.
 *
 * This method should be triggered on every page navigation.
 */
export function initUmcAPIClient(jwt: string) {
	cachedJwt = jwt;

	const baseUrl =
		apiConfig.APIGateway.baseUmcApiGatewayUrl +
		"/" +
		apiConfig.APIGateway.branch;
	umcApiClient.baseUrl = baseUrl;
	umcApiClient.setSecurityData({
		token: cachedJwt,
	});
}

export function initUmcUnauthAPIClient() {
	const baseUrl =
		apiConfig.APIGateway.baseUmcApiGatewayUrl +
		"/" +
		apiConfig.APIGateway.branch;

	unauthenticatedUmcApiClient.baseUrl = baseUrl;
}

/**
 * Sends requests using methods defined in HttpClient from types/umcApi.ts.
 * Swagger generates a HTTP Client with predefined methods for each known method.
 *
 * Since the context of the request is supplied on page navigation,
 * it is not necessary to pass in the information during the request.
 *
 * To call a request, call an existing route with the necessary parameter as follows:
 *
 * eg)
 * const res = await httpClient.users.getUser(username);
 */
export const useUmcApi = (): Api<{ token?: string }> => umcApiClient;

export const useUnauthenticatedUmcApi = (): Api<{}> =>
	unauthenticatedUmcApiClient;
