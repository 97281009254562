const apiConfig = {
	APIGateway: {
		/**
		 * Current API Branch name.
		 * Eg) Beta, Prod, AG-630-bales
		 * Used to specify the API Gateway stage the app sends the request to.
		 */
		branch: "Prod",

		/**
		 * The API Gateway URL for BaleLink.
		 * To replace with a different URL, go to AWS -> Stages -> Copy the invoke URL before the stage name.
		 */
		baseApiGatewayUrl:
			"https://7ge2j7wawa.execute-api.eu-west-1.amazonaws.com",

		/**
		 * The API Gateway URL for UMC Lambda.
		 * Necessary for user management through Cognito.
		 */
		baseUmcApiGatewayUrl:
			"https://4lmhim9w0i.execute-api.eu-west-1.amazonaws.com",
	},
};

export default apiConfig;
